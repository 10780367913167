import styled from "styled-components";
import { device } from "../utils/media-queries";

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 190px;
  background-color: ${({ isBlue, theme }) =>
    isBlue ? theme.colors.primary.surface : theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.main};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 10px;

  @media ${device.tablet} {
    max-width: unset;
  }

  @media ${device.mobile} {
    max-width: unset;
  }
`;

export default Block;
