import React from "react";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

import H2 from "../atoms/h2";
import H3 from "../atoms/h3";
import H4 from "../atoms/h4";
import Block from "../atoms/block";
import Button from "../atoms/button";

import MonitorIcon from "../images/monitor.svg";
import { device } from "../utils/media-queries";
import Text1 from "../atoms/text1";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.surface};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 925px;
  display: flex;
  justify-content: space-between;
  padding: 112px 0 172px 0;
  gap: 20px;

  @media ${device.laptop} {
    padding: 48px 40px 80px 40px;
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
    padding: 48px 40px 80px 40px;
  }

  @media ${device.mobile} {
    flex-direction: column-reverse;
    padding: 48px 20px 80px 20px;
  }
`;

const Monitor = styled.img`
  width: 400px;
  height: auto;
  position: relative;
  top: 16px;
  left: -100px;

  @media ${device.tablet} {
    display: none;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled(Text1)`
  margin: 80px 0 122px 0;
  padding-left: 16px;
  border-left: 2px solid ${({ theme }) => theme.colors.additional.green};

  @media ${device.tablet} {
    max-width: 400px;
  }

  @media ${device.tablet} {
    margin: 40px 0 48px 0;
  }
`;

const DesignAreas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 190px;

  @media ${device.tablet} {
    max-width: unset;
  }

  @media ${device.mobile} {
    max-width: unset;
  }
`;

const Blocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px 15px;
  }
`;

const BlueText = styled(H4)`
  max-width: 295px;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: 80px;

  @media ${device.tablet} {
    margin: 48px 0;
    max-width: unset;
  }

  @media ${device.mobile} {
    margin: 48px 0;
  }
`;

const InfoButton = styled(Button)`
  @media ${device.tablet} {
    align-self: center;
  }
`;

const Design = ({ scrollToOffset }) => {
  return (
    <PageWrapper id="design">
      <ContentWrapper>
        <ColumnWrapper>
          <Monitor src={MonitorIcon} />
          <ColumnWrapper>
            <BlueText>
              Let our award winning designers build products that will
              accelerate the growth of your business.
            </BlueText>
            <InfoButton
              isBlue
              iswide
              as={LinkScroll}
              smooth={true}
              offset={scrollToOffset}
              to="contact"
            >
              Get more information
            </InfoButton>
          </ColumnWrapper>
        </ColumnWrapper>
        <ColumnWrapper>
          <H2 isBlue>Design</H2>
          <Text isGrey>
            Although Blockbit.es is known as an engineering firm, we have a team
            of industry award winning designers. These creative geniuses
            continue to inspire us with their masterpieces.
          </Text>
          <DesignAreas>
            <H3 isBlue>Our areas of design</H3>
            <Blocks>
              <Block>UI/UX Design</Block>
              <Block>Branding</Block>
              <Block>Web Site Design</Block>
              <Block>Product Design</Block>
              <Block>App Design</Block>
            </Blocks>
          </DesignAreas>
        </ColumnWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Design;
