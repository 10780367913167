import React from "react";

import styled from "styled-components";
import ServiceCard from "../molecules/service-card";

import EngeneeringIcon from "../images/engeneering-icon.svg";
import DesignIcon from "../images/design-icon.svg";
import ProductIcon from "../images/product-icon.svg";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.surface};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 112px 0 88px 0;
  gap: 20px;

  @media ${device.desktop} {
    padding: 80px 40px;
  }

  @media ${device.laptop} {
    padding: 48px 30px;
    align-items: flex-start;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding: 48px 20px;
    gap: 32px;
  }
`;

const ServicesCards = ({ scrollToOffset }) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <ServiceCard
          icon={EngeneeringIcon}
          link="engeneering"
          header={"Engeneering"}
          scrollToOffset={scrollToOffset}
          p={
            "We will develop a new software solution from scratch, or customize an existing one suited to your specifications. Furthermore, we can increase your team’s capabilities with highly-skilled software experts in the technology or domain of your needs."
          }
        />
        <ServiceCard
          icon={DesignIcon}
          link="design"
          header={"Design"}
          scrollToOffset={scrollToOffset}
          p={
            "Our designers are proud to develop user centric software solutions which exceed our customers’ expectations and increased their revenue. Let us do that for you as well."
          }
        />
        <ServiceCard
          icon={ProductIcon}
          link="consulting"
          header={"Own Product"}
          scrollToOffset={scrollToOffset}
          p={
            "There are a number of proprietary, off the shelf solutions ready to be implemented. Among them is a platform for selling and buying turned down loan applications as well as an international money remittance platform."
          }
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ServicesCards;
