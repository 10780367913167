import React from "react";
import styled from "styled-components";

import TechNetworkIll from "../images/tech-network-ill.svg";

import Stack from "../molecules/stack";
import H2 from "../atoms/h2";
import H4 from "../atoms/h4";
import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 112px 0 172px 0;
  gap: 20px;

  @media ${device.laptop} {
    padding: 48px 40px;
  }

  @media ${device.mobile} {
    padding: 48px 20px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 626px;
  gap: 80px;
  font-size: 54px;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: 160px;
  position: relative;

  @media ${device.laptop} {
    margin-bottom: 80px;
  }

  @media ${device.tablet} {
    gap: 40px;
    margin-bottom: 48px;
  }
`;

const Illustration = styled.img`
  width: 476px;
  height: auto;
  position: absolute;
  right: -620px;
  top: -35px;

  @media ${device.laptop} {
    right: -500px;
    top: 0px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-left: 2px solid ${({ theme }) => theme.colors.additional.green};
  padding-left: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.secondary};
`;

const Engeineering = ({ scrollToOffset }) => {
  return (
    <PageWrapper id="engeneering">
      <ContentWrapper>
        <Description>
          <Illustration src={TechNetworkIll} />
          <H2 isBlue>Engeineering</H2>
          <TextWrapper>
            <H4>Full-cycle product development</H4>
            <Text>
              Therefore, even if you are not in the fintech, blockchain or cloud
              storage space, this will not prevent from providing you with an
              awesome digital solution. <br />
              <br /> Our explorations has led us to building a marketplace for
              the agricultural industry, or a long term apartment renting
              platform for a NYC real estate firm.
              <br />
              <br /> Although our expertise lies in fintech, blockchain and
              cloud, we are constantly looking for new ways to apply blockchain,
              fintech and cloud technologies.
            </Text>
          </TextWrapper>
        </Description>
        <Stack scrollToOffset={scrollToOffset} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Engeineering;
