import React from "react";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

import Block from "../atoms/block";
import Button from "../atoms/button";

import H3 from "../atoms/h3";
import H4 from "../atoms/h4";
import { device } from "../utils/media-queries";

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${device.mobile} {
    gap: 24px;
  }
`;

const H3Center = styled(H3)`
  text-align: center;
  max-width: 400px;
  align-self: center;

  @media ${device.laptop} {
    max-width: 280px;
  }

  @media ${device.mobile} {
    align-self: flex-start;
    text-align: left;
    max-width: 60%;
  }
`;

const Title = styled(H4)`
  max-width: 240px;

  @media ${device.mobile} {
    text-align: center;
    max-width: unset;
  }
`;

const TechStackWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Frontend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 400px;

  @media ${device.tablet} {
    max-width: unset;
  }
`;

const Backend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${device.tablet} {
    margin-top: 40px;
  }
`;

const FrontGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 20px;

  @media ${device.mobile} {
    gap: 8px 15px;
  }
`;

const BackGrid = styled(FrontGrid)`
  grid-template-columns: 1fr;
  gap: 8px;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: unset;
  }
`;

const TitleBlock = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  font-size: 16px;
  line-height: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 140px;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 48px;
    margin-top: 48px;
  }
`;

const Info = styled.div`
  max-width: 505px;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.primary.main};

  @media ${device.mobile} {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
`;

const Stack = ({ scrollToOffset }) => {
  return (
    <ContentWrapper>
      <H3Center isBlue>Our stacks include but not limited to:</H3Center>
      <TechStackWrapper>
        <Frontend>
          <Title>Front-end Development</Title>
          <FrontGrid>
            <TitleBlock>Web</TitleBlock>
            <TitleBlock>Mobile</TitleBlock>
            <Block isBlue>Vue.js</Block>
            <Block isBlue>Android</Block>
            <Block isBlue>React</Block>
            <Block isBlue>iOS</Block>
            <Block isBlue>Angular</Block>
            <Block isBlue>React Native</Block>
            <Block isBlue>KnockoutJs</Block>
          </FrontGrid>
        </Frontend>
        <Backend>
          <Title>Back-end Development</Title>
          <BackGrid>
            <TitleBlock>Using technologies</TitleBlock>
            <Block isBlue>Golang</Block>
            <Block isBlue>Net</Block>
            <Block isBlue>NodeJs</Block>
            <Block isBlue>Java</Block>
          </BackGrid>
        </Backend>
      </TechStackWrapper>
      <InfoWrapper>
        <Info>
          If you are interested in other stacks not mentioned above, we can hire
          a team or individual developer according to your requirements
          (outstaffing).
        </Info>
        <Button
          isBlue
          iswide
          as={LinkScroll}
          smooth={true}
          offset={scrollToOffset}
          to="contact"
        >
          Get more information
        </Button>
      </InfoWrapper>
    </ContentWrapper>
  );
};

export default Stack;
