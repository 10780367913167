import React from "react";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

import { device } from "../utils/media-queries";
import H3 from "../atoms/h3";
import Text1 from "../atoms/text1";

const CardWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px;
  border-radius: 30px;
  min-height: 292px;
  box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.1);
  }

  @media ${device.tablet} {
    min-height: unset;
    height: min-content;
    border-radius: 15px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.black};
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${device.laptop} {
    gap: 16px;
  }
`;

const TitleIcon = styled.img`
  width: 60px;
  height: 60px;

  @media ${device.laptop} {
    width: 45px;
    height: 45px;
  }

  @media ${device.tablet} {
    width: 30px;
    height: 30px;
  }
`;

const Text = styled(Text1)`
  margin-top: 24px;
`;

const ServiceCard = ({ icon, header, p, link, scrollToOffset }) => {
  return (
    <CardWrapper
      as={LinkScroll}
      smooth={true}
      offset={scrollToOffset}
      to={link}
    >
      <TitleWrapper>
        <TitleIcon src={icon} />
        <H3>{header}</H3>
      </TitleWrapper>
      <Text isGrey> {p}</Text>
    </CardWrapper>
  );
};

export default ServiceCard;
