import * as React from "react";

import SubpageBanner from "../molecules/subpage-banner";
import Layout from "../layout/Layout";
import Topbar from "../molecules/topbar";
import ServicesCards from "../organisms/services-cards";
import Engeineering from "../organisms/engeineering";
import Design from "../organisms/design";
import Consulting from "../organisms/consulting";
import Seo from "../layout/Seo";

const ServicesPage = () => {
  return (
    <Layout>
      <Topbar />
      <SubpageBanner
        title={"Services"}
        text={
          "We’ve helped leading brands design, build, and deliver their next-gen software products and digital experiences in the shortest possible time."
        }
      />
      <ServicesCards />
      <Engeineering />
      <Design />
      <Consulting />
    </Layout>
  );
};

export default ServicesPage;

export const Head = () => <Seo title="Blockbit.es | Services" />;
