import React from "react";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

import Button from "../atoms/button";
import H2 from "../atoms/h2";
import H4 from "../atoms/h4";
import { device } from "../utils/media-queries";

import MessagesImage from "../images/messages.svg";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 112px 0 172px 0;
  gap: 20px;

  @media ${device.laptop} {
    padding: 48px 40px 80px 40px;
  }

  @media ${device.tablet} {
    padding: 48px 40px 80px 40px;
  }

  @media ${device.mobile} {
    padding: 48px 20px 80px 20px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.div`
  max-width: 400px;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.neutral.secondary};
  margin: 80px 0 160px 0;
  padding-left: 16px;
  border-left: 2px solid ${({ theme }) => theme.colors.additional.green};

  @media ${device.laptop} {
    margin: 80px 0 80px 0;
  }

  @media ${device.tablet} {
    margin: 40px 0 48px 0;
  }

  @media ${device.mobile} {
    margin: 40px 0 48px 0;
  }
`;

const BlueText = styled(H4)`
  max-width: 400px;
  color: ${({ theme }) => theme.colors.primary.main};

  @media ${device.tablet} {
    max-width: unset;
  }
`;

const Messages = styled.img`
  width: 400px;
  height: auto;
  align-self: flex-start;
  position: relative;
  right: -105px;

  @media ${device.laptop} {
    right: -80px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

const InfoButton = styled(Button)`
  @media ${device.tablet} {
    align-self: center;
  }
`;

const Consulting = ({ scrollToOffset }) => {
  return (
    <PageWrapper id="consulting">
      <ContentWrapper>
        <RowWrapper>
          <ColumnWrapper>
            <H2 isBlue>Consulting</H2>
            <Text>
              As a startup ourselves, we have been through it all. Startups will
              not only benefit from our market leading software solutions, but
              also our coaching.
              <br />
              <br /> Starting from the bottom and working our way into becoming
              an industry leader, our position gives us unique insights into
              your needs. We are fully committed to accelerating the growth of
              your startup.
            </Text>
          </ColumnWrapper>
          <Messages src={MessagesImage} />
        </RowWrapper>
        <RowWrapper>
          <BlueText>
            As your adviser, Blockbite.es guides you through all stages of the
            process - from design to product delivery.
          </BlueText>
          <InfoButton
            isBlue
            iswide="true"
            as={LinkScroll}
            smooth={true}
            offset={scrollToOffset}
            to="contact"
          >
            Get more information
          </InfoButton>
        </RowWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Consulting;
