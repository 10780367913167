import React from "react";
import styled from "styled-components";

import { device } from "../utils/media-queries";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(80.9deg, #2982e2 33.12%, #00ffca 120.72%);
  color: ${({ theme }) => theme.colors.neutral.white};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 610px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 272px 0 160px 0;
  overflow-x: "hidden";

  @media ${device.laptop} {
    padding: 180px 40px 80px 40px;
  }

  @media ${device.tablet} {
    padding: 100px 40px 48px 40px;
  }

  @media ${device.mobile} {
    padding: 100px 35px 48px 35px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

const Title = styled.h1`
  font-weight: 800;
  font-size: 56px;
  letter-spacing: -0.01em;
  line-height: 72px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 16px;
  width: 100%;
  max-width: 600px;
  line-height: 150%;
  text-align: center;
`;

const SubpageBanner = ({ title, text }) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <Text>{text} </Text>
        </TextWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default SubpageBanner;
